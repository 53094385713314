import React from 'react';
import Layout from '../components/layout';

const AboutPage = () => (
  <Layout>
    <h1>About me</h1>
    <p>I'm a senior web developer, currently working in Houghton Mifflin Harcourt.</p>

    <h2>Languages (computer)</h2>
    <p>I work with Javascript and CSS every day.</p>
    <p>I've used C# to build enterprise applications.</p>
    <p>I used to build mobile apps with Java (Android), or C# (Xamarin) or HTML (Phonegap / Cordova).</p>
    <p>I use Python to work on personal data science and machine learning projects.</p>

    <h2>Languages (human)</h2>
    <p>English is my mother tongue. I can speak and read French at an intermediate level. I know a little Spanish.</p>

    <h2>Code</h2>
    <p>
      <a href="https://github.com/mlennox">My github</a>
    </p>

    <h2>Reading</h2>
    <p>
      I've been setting myself the goodreads reading challenge for the last few years. This year I've challenged myself
      to read 52 books. You can see my ongoing efforts for the{' '}
      <a href="https://www.goodreads.com/challenges/8863-2019-reading-challenge">2019 challenge on Goodreads</a>
    </p>

    <p>
      I have a list of{' '}
      <a href="https://www.goodreads.com/review/list/6591213-mark-lennox?shelf=technical-books">
        technology oriented books
      </a>{' '}
      I plan on reading too{' '}
    </p>

    <h2>Other online</h2>
    <p>
      I often cross-publish at <a href="https://medium.com/@mlennox">Medium</a> and my articles have been added to the{' '}
      <a href="https://hmh.engineering/">HMH Engineering blog</a> on medium
    </p>
    <p>
      I also cross-publish at <a href="https://dev.to/mlennox">Dev.to</a>
    </p>
    <p>
      I'm on <a href="https://www.linkedin.com/in/marklennox/">Linkedin</a> (no pressure to join my professional
      network, unless you want to)
    </p>
    <p>
      My twitter account <a href="https://twitter.com/webpusher">@webpusher</a>
    </p>
  </Layout>
);

export default AboutPage;
